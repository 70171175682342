import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ArticleCard from '../components/ArticleCard';
import GroupHeader from './GroupHeader';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function filename(image) {
	if(image === null)
		return "";
	var md5 = require('md5');
	var path = md5(image);
	return "https://d2rqvrnppmk7he.cloudfront.net/article-imagepool/full/" + path[0] + path[1] + "/" + path[2] + path[3] + "/" + image;
}

export default  function ClassExtented(props) {
	const {classes} = props;
  	return (
		<>
		    {classes.map(cl => (
				<>
					<Paper sx={{
			              p: 2,
			              mb: 2,
			            }}>
			            
			            <GroupHeader label={cl.name} itemsFounds={cl.itemsFounds}/>

	                    
						<Grid 	container 
								spacing={1}
								sx={{
					              mt: 2,
			            	}}
			            >		                    
							<>
								{cl.items.map(item => (
									<Grid item xs={12} md={8} lg={4}>
								    	<ArticleCard article={item} />
								    </Grid>
								 ))}

								<Grid item xs={12} md={8} lg={12}>
									<Box
									  m={1}
									  display="flex"
									  justifyContent="flex-end"
									  alignItems="flex-end"
									>
									<Button size="small" 
											variant="contained">Alle anzeigen
									</Button>
									</Box>								
								</Grid>
							</>	                    
						</Grid>
	                    
	                    
	            	</Paper>				
				</>
			))}
		</>
  );
}
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default  function Log(props) {
	const {searchResult, searchObj} = props;
  	return (
		<>
	      Log: <br/>
	      { Object.keys(searchResult).length !== 0 ? (	
			<>      
		      SearchObject:<br/>{searchObj}
		      <hr/>
		      SearchQuery:<br/>{searchResult.searchTerm}
		    </>
		   ) : (<></>)}
	    </>
  );
}
import React from 'react';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function valuetext(value) {
  return `${value}`;
}


export default function RangeSlider(props) {
	const {addFilterWidget} = props;
  	const [value, setValue] = React.useState([20, 37]);

	const handleChange = (event, newValue) => {
	    setValue(newValue);
	};
	const addFilter = () => {	
		let filterObj = {};
		filterObj.id = 100;
		filterObj.type = "widget";
		filterObj.value = "gewicht:["+ value[0] +" TO "+ value[1] +"]";
		addFilterWidget(filterObj);
  	};

  	return (
		<>
			<Slider
		        value={value}
		        onChange={handleChange}
		        valueLabelDisplay="auto"
		        aria-labelledby="range-slider"
		        getAriaValueText={valuetext}
		    />
			<Box
			  m={1}
			  display="flex"
			  justifyContent="flex-end"
			  alignItems="flex-end"
			>																					
				<Button size="small" 
						onClick={addFilter}
						variant="contained">übernehmen</Button>
			</Box>
		</>      
	);
}
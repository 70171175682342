import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SnackbarProvider, Snackbar } from 'notistack';


import Dashboard from "./pages/Dashboard";
import './App.css';

function App() {
  return (
    <Router>

    </Router>
  );
}

export default () => (
    <SnackbarProvider maxSnack={3} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        }}
    >
    <Router>
        <Routes>
        	<Route 
          		index      
      			element={<div><Dashboard/></div>}
    	 	/>
        </Routes>
    </Router>
    </SnackbarProvider>
);

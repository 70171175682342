import React , {Component, Fragment } from 'react'; 
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import AccountCircle from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import {withSnackbar } from 'notistack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ArticleCard from '../components/ArticleCard';
import ArticleCardFullSize from '../components/ArticleCardFullSize';
import ClassExtented from '../components/ClassExtented';
import WSlider from '../widgets/WSlider';


const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));


class SearchInput extends Component{

    constructor(props) {
        super(props);
        this.state = {
            globalsearchResult: {},
            language: "de",
            searchResult: {},
            searchTerm: "",
            rowReturn: 12,
            openProgress: false,
            layout: "l1",
            availability: "onlyAvailability",
            filters: [],
            pagination: 0,
            startPage: 1,
            sorted: "solr",
            test: [20, 37],
        }

		this.catchReturn = this.catchReturn.bind(this);
		this.checkFilter = this.checkFilter.bind(this);
		this.doSearch = this.doSearch.bind(this);
		this.addFilterClass = this.addFilterClass.bind(this);
		this.addFilterWidget = this.addFilterWidget.bind(this);
		this.addFilterCategorie = this.addFilterCategorie.bind(this);
		this.deleteFilter = this.deleteFilter.bind(this);
		this.handleChangeResults = this.handleChangeResults.bind(this);
		this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
		this.handlePaginationChange = this.handlePaginationChange.bind(this);
		
		this.handleChangeWidgets = this.handleChangeWidgets.bind(this);
    } 
    
    componentDidUpdate(prevProps) {
		if(prevProps.searchTerm != this.props.searchTerm) {
			this.setState({searchTerm: this.props.searchTerm}, () => {
			        this.doSearch();
			})		
		}	
	}
    
    
    checkFilter(id) {
		let checked = "";
		let ftemp = this.state.filters;
		ftemp.forEach(function(f) {
		    if (f.id === id) {
		        checked = "checked";
		    }
		})	
		console.log(checked);
		return checked;
	}

	handlePaginationChange(e) {
		this.setState({startPage: parseInt(e.target.textContent) }, () => {
		        this.doSearch();
		})
	}
	
	handleChangeWidgets(e) {
		
	}

	handleChangeLanguage(e) {
		this.setState({language: e.target.value}, () => {
		        this.doSearch();
		    })		
	}

	handleChangeResults(e) {
		this.setState({rowReturn: e.target.value}, () => {
		        this.doSearch();
		    })		
	}

	addFilterWidget(o) {
		let ftemp = this.state.filters;
		
		ftemp.push(o);
		this.setState({filters: ftemp}, () => {
		        this.doSearch();
		})		    
	}	

    
	addFilterClass(e) {
		let ftemp = this.state.filters;
		let filterObj = {};
		filterObj.id = e.currentTarget.getAttribute('data-id');
		filterObj.type = "class";
		filterObj.value = e.target.value;
		ftemp.push(filterObj);
		this.setState({filters: ftemp}, () => {
		        this.doSearch();
		})		    
	}	
	
	addFilterCategorie(e) {
		let ftemp = this.state.filters;
		let filterObj = {};
		filterObj.id = e.currentTarget.getAttribute('data-id');
		filterObj.type = "categorie";
		filterObj.value = e.target.value;
		ftemp.push(filterObj);
		this.setState({filters: ftemp}, () => {
		        this.doSearch();
		})		    
	}

	deleteFilter(e) {
		let ftemp = this.state.filters;
		ftemp.forEach(function(f) {
		    if (f.id === e) {
		        ftemp.shift() 
		    }
		})
		this.setState({filters: ftemp}, () => {
		        this.doSearch();
		})	 
	}



	setSearchTerm(e) {
	    this.setState({
	        searchTerm: e.target.value,
	    });  
	}

    catchReturn(ev) {
        if (ev.key === 'Enter') {
			this.setState({filters: [], startPage: 1}, () => {
		        this.doSearch();
		    });	
           ev.preventDefault();
        }
    }
    
    doSearch() {
		this.setState({
        	openProgress: true
        });			

		let searchObj = {};
		searchObj.language = this.state.language;
		searchObj.term = this.state.searchTerm;
		searchObj.exactTerm = true;
		if(this.state.sorted == "solr")
			searchObj.reimoSort = false;
		else
			searchObj.reimoSort = true;
		searchObj.availability = this.state.availability;
		searchObj.start = (this.state.startPage -1) * this.state.rowReturn;
		searchObj.rows = this.state.rowReturn;
		searchObj.mandatoryTerm = "articlegroup:1";
		let mandatoryTerms = []; 
		let filters = this.state.filters;
		if(!filters.length == 0) {
			filters.forEach(function(f) {
			    if (f.type == "class") {
			        mandatoryTerms.push("articletype_" + searchObj.language + ":" + f.value); 
			    }
			    if (f.type == "categorie") {
			        mandatoryTerms.push("category:" + f.value); 
			    }
			    if (f.type == "widget") {
			        mandatoryTerms.push(f.value); 
			    }
			    
			    
   
			});
			searchObj.mandatoryTerm	= mandatoryTerms.join(' or ');
		}
		
		if(this.state.availability == "onlyAvailability") {
			searchObj.mandatoryTerm = "" + searchObj.mandatoryTerm + " and -lifecycle:F"; 
		}


		this.props.setSearchObj(JSON.stringify(searchObj));

		axios.post('https://solr01.reimo-shops.de/extranet/rest/articles/search', 

//		axios.post('http://localhost:8080/extranet/rest/articles/search', 
			searchObj
		,
		{
		}
		).then(repsonse => {
			this.props.enqueueSnackbar('Suche beendet', { variant: 'info' });	
			this.props.setSResult(repsonse.data);		
			let pagination = Math.ceil(repsonse.data.itemsFounds / this.state.rowReturn);
			
			if(filters.length == 0) {
				this.setState({
	            	searchResult: repsonse.data,
	            	globalsearchResult: repsonse.data,
	            	openProgress: false,
	            	pagination: pagination
	            });			
			}
			else {
				this.setState({
	            	searchResult: repsonse.data,
	            	openProgress: false,
	            	pagination: pagination

	            });			
			}

			console.log(repsonse.data);
		}
			
		)
		.catch((error) => {
			this.props.enqueueSnackbar('Fehler bei der Suche', { variant: 'error' });
			this.setState({
            	openProgress: false
            });			

		});	
	}

    render(){
		const handleAlignment = (event, newAlignment) => {
		    this.setState({
		        layout: newAlignment,
		    });  			
		};
		const handlenewAvailability = (event, newAvailability) => {
			this.setState({availability: newAvailability}, () => {
			        this.doSearch();
			})				
		};
		const handlenewSorted = (event, newSorted) => {
			this.setState({sorted: newSorted}, () => {
			        this.doSearch();
			})				
		};
		
		
		
		let filterIDCounter = 0;
		return(
			<>
			{ Object.keys(this.state.searchResult).length === 0 ? (
			<Grid container justifyContent="center">
				<img src="/images/Reimo_Logo_2019-klein.jpeg" />
			</Grid>
			) : (<></>)}
			<FormControl variant="standard">
		        <InputLabel htmlFor="input-with-icon-adornment">
		          Suchbegriff:
		        </InputLabel>
		        <Input
		          id="input-with-icon-adornment"
		          onKeyPress={e => this.catchReturn(e)}
		          onChange={e => this.setSearchTerm(e)}
		          value={this.state.searchTerm}
		          startAdornment={
		            <InputAdornment position="start">
		              <AccountCircle />
		            </InputAdornment>
		          }
		        />
	      	</FormControl>
	      	
			{ Object.keys(this.state.searchResult).length !== 0 ? (
		        <>
			        <br/>
			        <InputLabel htmlFor="input-with-icon-adornment">
			          Ergebnis für "{this.state.searchTerm}" gefunden: {this.state.searchResult.itemsFounds} Artikel
			          &nbsp;Dauer: {this.state.searchResult.qtime} ms
			        </InputLabel>
			        <AppBar position="static">
			      	<Toolbar
			            sx={{
			              pr: '24px',
			              height: '12px', // keep right padding when drawer closed
			              background: '#d27619',
			            }}
			          >
			            <Typography
			              component="h6"
			              variant="h6"
			              color="inherit"
			              noWrap
			              sx={{ flexGrow: 1 }}
			            >
			              Anzeige:&nbsp;
							<ToggleButtonGroup
							      value={this.state.layout}
							      exclusive
							  	  onChange={handleAlignment}
							      aria-label="text alignment"
							    >			              
								 <ToggleButton value="l1" aria-label="Layout1">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
									<path d="M15.2,16H9.8C9.4,16,9,15.6,9,15.2V9.8C9,9.4,9.4,9,9.8,9h5.4C15.6,9,16,9.4,16,9.8v5.4C16,15.6,15.6,16,15.2,16z M15.2,7
										H9.8C9.4,7,9,6.6,9,6.2V0.8C9,0.4,9.4,0,9.8,0h5.4C15.6,0,16,0.4,16,0.8v5.4C16,6.6,15.6,7,15.2,7z M6.2,16H0.8
										C0.4,16,0,15.6,0,15.2V9.8C0,9.4,0.4,9,0.8,9h5.4C6.6,9,7,9.4,7,9.8v5.4C7,15.6,6.6,16,6.2,16z M6.2,7H0.8C0.4,7,0,6.6,0,6.2V0.8
										C0,0.4,0.4,0,0.8,0h5.4C6.6,0,7,0.4,7,0.8v5.4C7,6.6,6.6,7,6.2,7z"></path>
									</svg>
								</ToggleButton>
								 <ToggleButton value="l4" aria-label="Layout4">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
									<path d="M15.2,16H0.8C0.4,16,0,15.6,0,15.2v-2.4C0,12.4,0.4,12,0.8,12h14.4c0.4,0,0.8,0.4,0.8,0.8v2.4C16,15.6,15.6,16,15.2,16z
										 M15.2,10H0.8C0.4,10,0,9.6,0,9.2V6.8C0,6.4,0.4,6,0.8,6h14.4C15.6,6,16,6.4,16,6.8v2.4C16,9.6,15.6,10,15.2,10z M15.2,4H0.8
										C0.4,4,0,3.6,0,3.2V0.8C0,0.4,0.4,0,0.8,0h14.4C15.6,0,16,0.4,16,0.8v2.4C16,3.6,15.6,4,15.2,4z"></path>
									</svg>
								</ToggleButton>	
								 <ToggleButton value="l3" aria-label="Layout3">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
									<path d="M15.2,16H0.8C0.4,16,0,15.6,0,15.2V9.8C0,9.4,0.4,9,0.8,9h14.4C15.6,9,16,9.4,16,9.8v5.4C16,15.6,15.6,16,15.2,16z M15.2,7
										H0.8C0.4,7,0,6.6,0,6.2V0.8C0,0.4,0.4,0,0.8,0h14.4C15.6,0,16,0.4,16,0.8v5.4C16,6.6,15.6,7,15.2,7z"></path>
									</svg>
								</ToggleButton>	
	
								 <ToggleButton value="l2" aria-label="Layout2">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
									<path d="M16,0.8v14.4c0,0.4-0.4,0.8-0.8,0.8H9.8C9.4,16,9,15.6,9,15.2V0.8C9,0.4,9.4,0,9.8,0l5.4,0C15.6,0,16,0.4,16,0.8z M7,0.8
										v14.4C7,15.6,6.6,16,6.2,16H0.8C0.4,16,0,15.6,0,15.2L0,0.8C0,0.4,0.4,0,0.8,0l5.4,0C6.6,0,7,0.4,7,0.8z"></path>
									</svg>
								</ToggleButton>							

							</ToggleButtonGroup>														
			            </Typography>
						<Typography
			              component="h6"
			              variant="h6"
			              color="inherit"
			              noWrap
			              sx={{ flexGrow: 1 }}
			            >
			              Verfügbarkeit:&nbsp;
							<ToggleButtonGroup
							      value={this.state.availability}
							      exclusive
							  	  onChange={handlenewAvailability}
							      aria-label="Availability"
							    >			              
								 <ToggleButton value="onlyAvailability" aria-label="onlyAvailability">
									<ShoppingBasketIcon />
								</ToggleButton>
								 <ToggleButton value="all" aria-label="All">
									<AllInclusiveIcon />
								</ToggleButton>	
							</ToggleButtonGroup>														
			            </Typography>

						<Typography
			              component="h6"
			              variant="h6"
			              color="inherit"
			              noWrap
			              sx={{ flexGrow: 1 }}
			            >
			              Sortierung:&nbsp;
							<ToggleButtonGroup
							      value={this.state.sorted}
							      exclusive
							  	  onChange={handlenewSorted}
							      aria-label="Availability"
							    >			              
								 <ToggleButton value="reimo" aria-label="onlyAvailability">
									<img src="/images/reimo_sm.png" height="32px"/>
								</ToggleButton>
								 <ToggleButton value="solr" aria-label="All">
									<img src="/images/solr.png" height="32px"/>
								</ToggleButton>	
							</ToggleButtonGroup>														
			            </Typography>
			            									            
						<Box sx={{ minWidth: 120, m: 0, p: 0 }}>
						      <FormControl size="small">
						        <InputLabel id="demo-simple-select-label">Anzahl</InputLabel>
						        <Select
						          labelId="demo-simple-select-label"
						          id="demo-simple-select"
						          value={this.state.rowReturn}
						          label="Anzahl"
						          onChange={this.handleChangeResults}
						        >
						          <MenuItem value={12}>12</MenuItem>
						          <MenuItem value={24}>24</MenuItem>
						          <MenuItem value={36}>36</MenuItem>
						        </Select>
						      </FormControl>
						      <FormControl size="small" sx={{  ml: 1}}>
						        <InputLabel id="demo-simple-select-label">Sprache</InputLabel>
						        <Select
						          labelId="demo-simple-select-label"
						          id="demo-simple-select"
						          value={this.state.language}
						          label="Sprache"
						          onChange={this.handleChangeLanguage}
						        >
						          <MenuItem value={"de"}>Deutsch</MenuItem>
						          <MenuItem value={"en"}>English</MenuItem>
						          <MenuItem value={"fr"}>Französich</MenuItem>
						        </Select>
						      </FormControl>
						</Box>				            
			          </Toolbar>
			        </AppBar>
			        <br/>
					<Paper
					      sx={{
					        display: 'flex',
					        justifyContent: 'center',
					        flexWrap: 'wrap',
					        listStyle: 'none',
					        p: 0.5,
					        m: 0,
					        mb: 2,
					      }}
					      component="ul"
					    >		
					    {this.state.filters.map(filter => (	        
							<ListItem data-id={filter.id}>
					            <Chip
					              label={filter.value}
					              
					              onDelete={(e) => this.deleteFilter(filter.id)}
					            />
					        </ListItem>			        
				        ))}
					</Paper>			        
			        
		            <Grid container spacing={1}>
	              		<Grid item xs={12} md={8} lg={3}>
	              		Filter:
	              		<div>
					      <Accordion>
					        <AccordionSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-controls="panel1a-content"
					          id="panel1a-header"
					        >
					        	<Typography>Artikelklassen</Typography>
					        </AccordionSummary>
					        <AccordionDetails>					          
						          <FormGroup>
							      	{this.state.globalsearchResult.classes.map(item => (									
								      <Accordion>
								        <AccordionSummary
								          expandIcon={<ExpandMoreIcon />}
								          aria-controls="panel1a-content"
								          id="panel1a-header"
								        >									
								    	<FormControlLabel value={item.name} control={<Checkbox {...this.checkFilter(filterIDCounter)} onClick={this.addFilterClass} data-id={filterIDCounter++} />} label={item.name} />
								        </AccordionSummary>
								        <AccordionDetails>					          
								        {item.filterItems.map(x => (
											<Paper 	variant="outlined"
													sx={{
												        p: 0.5,
												        m: 0,
												        mb: 2,
												      }} 
											>
												{x.name}
												<hr/>
												<WSlider addFilterWidget={this.addFilterWidget}/>	
											</Paper>
										))}
								        </AccordionDetails>					          

								      </Accordion>

									))}
								  </FormGroup>
    
					        </AccordionDetails>
					      </Accordion>
					      <Accordion>
					        <AccordionSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-controls="panel2a-content"
					          id="panel2a-header"
					        >
					          <Typography>Kategorieen</Typography>
					        </AccordionSummary>
					        <AccordionDetails>
					          <FormGroup>
						      	{this.state.globalsearchResult.categories.map(item => (				
									<FormControlLabel value={item.code} control={<Checkbox {...this.checkFilter(filterIDCounter)} onClick={this.addFilterCategorie} data-id={filterIDCounter++} />} label={item.label} />
								))}
							  </FormGroup>
					        </AccordionDetails>
					      </Accordion>
					      <Accordion >
					        <AccordionSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-controls="panel3a-content"
					          id="panel3a-header"
					        >
					          <Typography>Hersteller</Typography>
					        </AccordionSummary>
					      </Accordion>
					    </div>
	              		</Grid>
	              		<Grid item xs={12} md={8} lg={9}>
				            <Grid container spacing={1}>								
					              <>
									{(this.state.layout === "l1" &&
										<>
											<Grid item xs={12} md={8} lg={12}>
												<Box
												  m={1}
												  display="flex"
												  justifyContent="flex-end"
												  alignItems="flex-end"
												>

													 <Pagination 	count={this.state.pagination} 
													 				color="primary" 
													 				sx={{
															        	mt: 0,
															      	}}
															      	onChange={this.handlePaginationChange} 
															      	page={this.state.startPage}
															      />
												</Box>
										    </Grid>
	
											{this.state.searchResult.items.map(item => (
												<Grid item xs={12} md={8} lg={4}>
											    	<ArticleCard article={item} />
											    </Grid>
											))}
											
											<Grid item xs={12} md={8} lg={12}>
												<Box
													  m={1}
													  display="flex"
													  justifyContent="flex-end"
													  alignItems="flex-end"
												>
													<Pagination 	count={this.state.pagination} 
														 				color="primary" 
														 				sx={{
																        	mt: 2,
																      	}}
																      	onChange={this.handlePaginationChange} 
															      		page={this.state.startPage}

													/>
												</Box>
											</Grid>
										</>
									 )
									|| (this.state.layout === "l2" &&
									    <Grid item xs={12} md={8} lg={4}>
										    <>222</>
									    </Grid>)					              
									|| (this.state.layout === "l3" &&
										<Grid item xs={12} md={8} lg={12}>
										    <ClassExtented classes={this.state.searchResult.classes} />
									    </Grid>)					              
									|| (this.state.layout === "l4" &&
										<>
											<Grid item xs={12} md={8} lg={12}>
												<Box
												  m={1}
												  display="flex"
												  justifyContent="flex-end"
												  alignItems="flex-end"
												>

													 <Pagination 	count={this.state.pagination} 
													 				color="primary" 
													 				sx={{
															        	mt: 0,
															      	}}
															      	onChange={this.handlePaginationChange} 
															      	page={this.state.startPage}
															      />
												</Box>
										    </Grid>										
											{this.state.searchResult.items.map(item => (
											    <Grid item xs={12} md={8} lg={12}>	
											    	<ArticleCardFullSize article={item} />
											    </Grid>
										    ))}
										    <Grid item xs={12} md={8} lg={12}>
												<Box
												  m={1}
												  display="flex"
												  justifyContent="flex-end"
												  alignItems="flex-end"
												>

													 <Pagination 	count={this.state.pagination} 
													 				color="primary" 
													 				sx={{
															        	mt: 0,
															      	}}
															      	onChange={this.handlePaginationChange} 
															      	page={this.state.startPage}
															      />
												</Box>
										    </Grid>
									    </> 
									   )															              
					              }
					              </>
				           </Grid>
	              		</Grid>
			        </Grid>
			        


	           </>
			) : (<></>)}	
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					  open={this.state.openProgress}					  
					>
				  <CircularProgress color="inherit" />
			</Backdrop>			
	      	</>
		)
	}

};


export default withSnackbar(SearchInput);   


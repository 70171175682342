import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

function filename(image) {
	if(image === null)
		return "";
	var md5 = require('md5');
	var path = md5(image);
	return "https://d2rqvrnppmk7he.cloudfront.net/article-imagepool/full/" + path[0] + path[1] + "/" + path[2] + path[3] + "/" + image;
}

export default  function ArticleCardFullSize(props) {
	const {article} = props;
  	return (
	    <Paper
	      sx={{
	        p: 2,
	        margin: 'auto',
	        minWidth: "100%",
	        minHeight: 250,
	        flexGrow: 1,
	        backgroundColor: (theme) =>
	          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	      }}
	    >
	      <Grid container spacing={2}>
	        <Grid item>
	          <ButtonBase sx={{ width: 128, height: 128 }}>
	            <Img alt="complex" src={filename(article.image)} />
	          </ButtonBase>
	        </Grid>
	        <Grid item xs={12} sm container>
	          <Grid item xs container direction="column" spacing={2}>
	            <Grid item xs>
	              <Typography gutterBottom variant="subtitle1" component="div"
	              dangerouslySetInnerHTML={{__html: article.caption}}>
					
	              </Typography>
	              <Typography variant="body2" color="text.secondary">
	                Artnr: {article.artnr}
	                
	              </Typography>
	            </Grid>
	            <Grid item>
	              <Typography variant="body2" color="text.secondary">
	                Klasse: {article.articletyp}<br/>
	                Cycle: {article.lifecycle}<br/>
	                ReimoSort: {article.s_reimoSort}<br/>
	                SolrScoring: {article.s_scoring}	                
	              </Typography>
	            </Grid>
	          </Grid>

	        </Grid>
	      </Grid>
	    </Paper>
  );
}
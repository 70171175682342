import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper, { PaperProps } from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Log from '../components/Log';
import DashboardDetail from '../components/DashboardDetail';
import SearchInput from './SearchInput';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TextField from '@mui/material/TextField';


function Copyright(props) {
	return (
    	<Typography variant="body2" color="text.secondary" align="center" {...props}>
      		{'Copyright © '}
      		<Link color="inherit" href="https://mui.com/">
        		Reimo
      		</Link>{' '}
      		{new Date().getFullYear()}
      		{'.'}
    	</Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== 'open',
	})(({ theme, open }) => ({
  		zIndex: theme.zIndex.drawer + 1,
  		transition: theme.transitions.create(['width', 'margin'], {
    	easing: theme.transitions.easing.sharp,
    	duration: theme.transitions.duration.leavingScreen,
  	}),
  	...(open && {
    	marginLeft: drawerWidth,
    				width: `calc(100% - ${drawerWidth}px)`,
    				transition: theme.transitions.create(['width', 'margin'], {
      				easing: theme.transitions.easing.sharp,
      				duration: theme.transitions.duration.enteringScreen,
    	}),
  	}),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const mdTheme = createTheme();

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
    	children: React.ReactElement;
  	},
  	ref: React.Ref<unknown>,
	) {
  		return <Slide direction="up" ref={ref} {...props} />;
});



function DashboardContent() {
	const [searchTerm, setSearchTerm] = React.useState("");
	const [open, setOpen] = React.useState(true);
  	const [searchResult, setSearchResult] = React.useState({});
  	const [searchObj, setSearchObj] = React.useState({});
 	const [openPrefs, setOpenPrefs] = React.useState(false);
  	const [openDashboard, setOpenDashboard] = React.useState(false);

  	const handleClickOpenDashboard = () => {
    	setOpenDashboard(true);
  	};

  	const handleCloseDashboard = () => {
    	setOpenDashboard(false);
  	};

  	const handleClickOpenPrefs = () => {
    	setOpenPrefs(true);
  	};

  	const handleClosePrefs = () => {
    	setOpenPrefs(false);
  	};
  
  	
  	const search1 = () => {
		setSearchTerm("Kühlschrank");
  	};
  	
  	const search2 = () => {
		setSearchTerm("Fenster Carbest");
  	};
  	
  	const search3 = () => {
		setSearchTerm("Multirail T4");
  	};

  	const toggleDrawer = () => {
    	setOpen(!open);
  	};

  	return (
    	<ThemeProvider theme={mdTheme}>
     		<Box sx={{ display: 'flex' }}>
        		<CssBaseline />
        		<AppBar position="absolute" open={open}>
          			<Toolbar sx={{
		              		 pr: '24px', 
		            }}
		         >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Reimo Suche TestPage
            </Typography>
            <IconButton color="inherit">
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
            Made with love and <img src="/images/solr.png" height="32px"/><img src="/images/react.png" height="32px"/><img src="/images/java.png" height="32px"/>
            </Typography>

            </IconButton>
          </Toolbar>
        </AppBar>
        
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
                <ListItemButton onClick={handleClickOpenDashboard}>
      				<ListItemIcon>
        				<DashboardIcon />
      				</ListItemIcon>
      				<ListItemText primary="Dashboard" />
    			</ListItemButton>
			    <ListItemButton onClick={handleClickOpenPrefs}>
			  		<ListItemIcon>
 						<SettingsIcon/>
					</ListItemIcon>
			      	<ListItemText primary="Einstellungen" />
			    </ListItemButton>
            	<Divider sx={{ my: 1 }} />


			    <ListSubheader component="div" inset>
					Gespeicherte Suchen
			    </ListSubheader>
			    <ListItemButton onClick={search1}>
			 		<ListItemIcon>
			        	<AssignmentIcon />
			      	</ListItemIcon>
					<ListItemText primary="Kühlschrank" />
			    </ListItemButton>
			    <ListItemButton onClick={search2}>
			 		<ListItemIcon>
			        	<AssignmentIcon />
			      	</ListItemIcon>
			      	<ListItemText primary="Fenster Carbest" />
			    </ListItemButton>
			    <ListItemButton onClick={search3}>
			      	<ListItemIcon>
			        	<AssignmentIcon />
			      	</ListItemIcon>
			      	<ListItemText primary="Multirail t4" />
			    </ListItemButton>

          </List>
        </Drawer>
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8} lg={12}>
                <Paper
                  sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minheight: 240,
                  }}
                >
	                <SearchInput setSResult={setSearchResult} setSearchObj={setSearchObj} searchTerm={searchTerm}/>
                </Paper>
              </Grid>
              
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                	<Log searchResult={searchResult} searchObj={searchObj}/>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      
      <Dialog
      	width="md"
        open={openPrefs}
        onClose={handleClosePrefs}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Einstellungen
        </DialogTitle>
        <DialogContent sx={{
					m: 2
	          }}>
			<Box   component="main"
	          sx={{
					width: '320px'
	          }}
			>
			Fine tunning der Suchfelder:<br/>
	          <DialogContentText>
	            <TextField
		          required
		          id="outlined-required"
		          label="promo"
		          defaultValue="10"
		        />
	          </DialogContentText>
			</Box>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClosePrefs}>
            Cancel
          </Button>
          <Button onClick={handleClosePrefs}>OK</Button>
        </DialogActions>
      </Dialog>
      
	  <Dialog
        fullScreen
        open={openDashboard}
        onClose={handleCloseDashboard}
        TransitionComponent={Transition}
      >
		<AppBar sx={{ position: 'relative' }}>
   			<Toolbar>
	            <IconButton
	              edge="start"
	              color="inherit"
	              onClick={handleCloseDashboard}
	              aria-label="close"
	            >
              		<CloseIcon />
            	</IconButton>
	            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
	              Dashboard
	            </Typography>
	            <Button autoFocus color="inherit" onClick={handleCloseDashboard}>
	              save
	            </Button>
       		</Toolbar>
        </AppBar>

 		<Box   component="main"
	          sx={{
	            backgroundColor: (theme) =>
	              theme.palette.mode === 'light'
	                ? theme.palette.grey[100]
	                : theme.palette.grey[900],
	            flexGrow: 1,
	            height: '100vh',
	            overflow: 'auto',
	          }}
		>
     		<Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
		    	<DashboardDetail />
      		</Container>
  		</Box>

      </Dialog>      
      
      
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
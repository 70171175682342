import React from "react";

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';





export default function GroupHeader(props) {
	const {label, itemsFounds} = props;

	return (
	    <AppBar
	      position="static"
	      style={{ borderRadius: "5px" }}
	    >
	      <Toolbar>
	        <Grid
		      justify="space-between" // Add it here :)
		      container 
		      spacing={24}
		    >
    			<Grid item>
			        <Typography variant="h6" >
			          {label}({itemsFounds})
			        </Typography>
      			</Grid>
			</Grid>
	      </Toolbar>
	    </AppBar>
	);
}
import React , {Component, Fragment } from 'react'; 
import Grid from '@mui/material/Grid';
import {withSnackbar } from 'notistack';

import Paper from '@mui/material/Paper';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Box from '@mui/material/Box';


ChartJS.register(ArcElement, Tooltip, Legend);


	export const totalData = {
	  meta: {
		articles: 34392,
		producer: 98,
		categories: 1346,
		classes: 124,
	  },
	  healthy: {	
		  labels: ['Korrekt', 'Hersteller fehlt'],
		  datasets: [
		    {
		      label: '# of Votes',
		      data: [20578, 13862],
		      backgroundColor: [
		        'rgba(38, 213, 104, 1.0)',
		        'rgba(213, 38, 71, 1.0)',
		        'rgba(255, 206, 86, 0.2)',
		        'rgba(75, 192, 192, 0.2)',
		        'rgba(153, 102, 255, 0.2)',
		        'rgba(255, 159, 64, 0.2)',
		      ],
		      borderColor: [
		        'rgba(255, 99, 132, 1)',
		        'rgba(54, 162, 235, 1)',
		        'rgba(255, 206, 86, 1)',
		        'rgba(75, 192, 192, 1)',
		        'rgba(153, 102, 255, 1)',
		        'rgba(255, 159, 64, 1)',
		      ],
		      borderWidth: 1,
		    },
		  ],
		}
	 };





class DashboardDetail extends Component{



    constructor(props) {
        super(props);
    } 
    
    render(){
		const result = JSON.parse('{"articleTotal": 1000, "producerTotal": 112}');
	console.log(result);
		return(
			<>
				<Grid container  spacing={2} style={{marginTop: "25px"}}>
	                <Grid item md={12}>
                        <Paper >
                            <div className="card-icon">
                                <span className="fa fa-database innerCenter"></span>
                            </div>
                            <span className="float-right headlineDashboard" >Übersicht
                            </span>
                        	<hr/>
                        	<Box 
						          sx={{
						            m: 1,
						          }}
							>
	                        	Artikel: {totalData.meta.articles}
	                        	<br/>
	                        	Hersteller: {totalData.meta.producer}
	                        	<br/>
	                        	Kategorien: {totalData.meta.categories} 
	                        	<br/>
	                        	Artikelklassen: {totalData.meta.classes}
	                        	<br/>
							</Box>
                    	</Paper>
	                  </Grid>
				     
				     <Grid style={{width: "15%"}} key={1} item>
						<Paper>
                            <div className="card-icon">
                                <span className="fa fa-database innerCenter"></span>
                            </div>
                            <span className="float-right headlineDashboard" >Artikel Healthy
                            </span>
                            <Doughnut data={totalData.healthy} 
						  	/>
						    <hr/>
                            
                    	</Paper>
	                  </Grid>
	                  	                  
				     <Grid style={{width: "33%", height: "120px"}} key={1} item>
						<Paper>
                            <div className="card-icon">
                                <span className="fa fa-database innerCenter"></span>
                            </div>
                            <span className="float-right headlineDashboard" >Artikel Healthy
                            </span>
                            
                            
                            <hr/>
                            
                    	</Paper>
	                  </Grid>
	       		</Grid>
	      	</>
		)
	}

};


export default withSnackbar(DashboardDetail);   

